import React from "react"
import {
  Theme,
  PreStyledComponents,
  Link,
  SocialMediaLinks,
  IconPhone,
  IconAt,
  IconClock,
  Section,
  Container,
  Row,
  Box,
  Heading,
  Panel
} from "@life-without-barriers/react-components"
import { phoneHelpers } from "@life-without-barriers/utilities"
import { DisabilityFormKind } from "@life-without-barriers/shared-types"
import styled from "styled-components"

import { DisabilityForm } from "@life-without-barriers/shared-contact-form"

const { disabilityTheme } = Theme

const { IconWrapper } = PreStyledComponents

const SpacedSpan = styled.span`
  display: inline-block;
  min-width: 100px;
`

interface Props {
  title: string
  formId: string
  formKind: DisabilityFormKind
  phoneNumber: string
  emailAddress: string
  subtitle?: string
  noVacancy?: boolean
}

const ContactUsSection = ({
  title,
  subtitle,
  formId,
  formKind,
  phoneNumber,
  emailAddress,
  noVacancy
}: Props) => {
  const phoneNumberAttrs = phoneHelpers.toHtmlAttributes(phoneNumber)

  const NoVacancyCTA = () => (
    <Row className="flex-wrap layout-stack pv4">
      <Box className="w-100">
        <Panel background="bg-lwb-grey-xxx-light" height="initial">
          <Heading size={3} topMargin="none">
            Supported Independent Living (SIL)
          </Heading>
          <p>Your permanent home in shared accommodation or on your own</p>
        </Panel>
      </Box>
      <Box className="w-100">
        <Panel background="bg-lwb-grey-xxx-light" height="initial">
          <Heading size={3} topMargin="none">
            Specialist Disability Accommodation (SDA)
          </Heading>
          <p>Specialist Disability Accommodation (SDA)</p>
        </Panel>
      </Box>
      <Box className="w-100">
        <Panel background="bg-lwb-grey-xxx-light" height="initial">
          <Heading size={3} topMargin="none">
            Medium-term accommodation
          </Heading>
          <p>Temporary housing while you wait for your permanent home</p>
        </Panel>
      </Box>
    </Row>
  )

  const ContactUsContent = () => (
    <Container>
      <Row className="flex-wrap">
        <Box className="w-100 w-seven-twelfths-l layout-gap">
          <Heading size={3} topMargin="none">
            {title}
          </Heading>
          {noVacancy ? <NoVacancyCTA /> : ""}
          {subtitle && <div className="mb4 tc-nc tl-ns">{subtitle}</div>}
          <div className="pl1 pt3-ns mt3 mt4-l flex flex-column flex-row-l">
            <div className="w-40-l">
              <div className="flex items-center">
                <IconWrapper size="30" color={disabilityTheme.xxxlight}>
                  <IconPhone
                    height="20"
                    width="20"
                    color={disabilityTheme.dark}
                  />
                </IconWrapper>
                <div className="pl3">
                  <Link
                    className="black fw3 no-underline-ns f3-ns fw8-ns"
                    to={phoneNumberAttrs.link}
                    ariaLabel={phoneNumberAttrs.ariaLabel}
                  >
                    {phoneNumberAttrs.text}
                  </Link>
                </div>
              </div>
              <div className="mt2 flex items-center">
                <IconWrapper size="30" color={disabilityTheme.xxxlight}>
                  <IconAt height="20" width="20" color={disabilityTheme.dark} />
                </IconWrapper>
                <div className="pl3">
                  <Link className="black fw4" to={`mailto:${emailAddress}`}>
                    {emailAddress}
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex pt4 pv4-ns pv0-l pl5-l">
              <IconWrapper size="30" color={disabilityTheme.xxxlight}>
                <IconClock
                  height="20"
                  width="20"
                  color={disabilityTheme.dark}
                />
              </IconWrapper>
              <div className="pl3">
                <p className="mt0 fw8">Opening hours (AEST)</p>
                <p className="mt1">
                  <SpacedSpan>Mon - Fri</SpacedSpan>
                  8:30AM - 5PM
                </p>
                <p className="mt0">
                  <SpacedSpan>Sat - Sun</SpacedSpan>
                  Closed
                </p>
              </div>
            </div>
          </div>
          <div className="mt4">
            <p>
              If you have hearing or speech impairments, National Relay Service
              can help you with your call.
            </p>
          </div>
          <div className="mt4">
            <div className="pb2">
              <div>
                <span className="fw8 pr1">Voice Relay</span>
                <Link
                  className="black fw3 link"
                  to="tel:1300555727"
                  ariaLabel="Phone number 1 3 0 0. 5 5 5. 7 2 7"
                >
                  1300 555 727
                </Link>
              </div>
              <div>
                <span className="fw8 pr1">TTY</span>
                <Link
                  className="black fw3 link"
                  to="tel:133677"
                  ariaLabel="Phone number 1 3. 3 6. 7 7"
                >
                  133 677
                </Link>
              </div>
              <div>
                <span className="fw8 pr1">SMS relay number</span>
                <Link
                  className="black fw3 link"
                  to="tel:0423677767"
                  ariaLabel="Phone number 0 4 2 3. 6 7 7. 7 6 7"
                >
                  0423 677 767
                </Link>
              </div>
            </div>
          </div>
          <div className="mt4 flex flex-wrap items-stretch">
            <SocialMediaLinks />
          </div>
        </Box>
        <Box className="w-100 w-third-l mt4 mt0-l">
          <DisabilityForm formId={formId} formKind={formKind} />
        </Box>
      </Row>
    </Container>
  )

  return noVacancy ? (
    <ContactUsContent />
  ) : (
    <Section background="bg-hex-image">
      <ContactUsContent />
    </Section>
  )
}

export default ContactUsSection
