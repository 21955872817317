import { Site } from "@life-without-barriers/gatsby-common"
import { graphql } from "gatsby"
import React from "react"
import {
  Helmet,
  BannerFeatured,
  SectionBanner,
  ReadMoreLink,
  IconGroceries,
  IconHousingAlt,
  IconSupportCoordination,
  PromoPanelRightImage,
  Variables,
  NavigationTiles,
  Section,
  Container,
  Row,
  Box,
  Heading,
  LinkButton
} from "@life-without-barriers/react-components"
import { IGatsbyImageData } from "gatsby-plugin-image"
import Modal from "react-modal"

import Layout from "../../components/disability/Layout"
import NdisJourney from "../../components/disability/NdisJourney"
import ContactUsSection from "../../components/disability/ContactUsSection"
import {
  SupportCoordination,
  SupportedIndependentLiving,
  DailyLivingSkills
} from "../../components/disability/RelatedPagesContent"
import LocationModal from "../../components/disability/LocationModal"

const { greyXXXLight } = Variables

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerHeroImage: IGatsbyImageData
    bannerWhyImage: IGatsbyImageData
    bannerLocationImage: IGatsbyImageData
  }
}

const iconSelector = (
  type: string,
  color: string,
  height?: string
): JSX.Element | undefined => {
  if (type === "groceries") {
    return <IconGroceries color={color} height={height} />
  }
  if (type === "housingalt") {
    return <IconHousingAlt color={color} height={height} />
  }
  if (type === "supportcoordination") {
    return <IconSupportCoordination color={color} height={height} />
  }
  return undefined
}

Modal.setAppElement("#___gatsby")

const IndexPage = ({
  location,
  data: {
    site: { siteMetadata },
    bannerHeroImage,
    bannerWhyImage,
    bannerLocationImage
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`Disability Services | ${siteMetadata.title}`}
        description="Our personalised Disability Services are available across Australia. We're here to support you, so you can live life the way you want."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={bannerHeroImage}
        includeGoogleSchema
      />
      <Layout>
        <BannerFeatured image={bannerHeroImage} alt="">
          <div className="flex-ns justify-end-ns pt5 pt6-l pb5">
            <div className="w-100 w-50-l featured-banner-content tc tl-l">
              <div className="mw6-l">
                <h1 className="banner-title ma0 ttu vera-mono color-lwb-white">
                  Live the Life You Want
                </h1>
                <p className="fw8 lh-copy copy-lrg">
                  <span className="di-ns">
                    When it comes to choosing the disability supports you need,
                    it&apos;s your life and your choice. We&apos;re here to
                    support your decisions, so you can live life the way you
                    want to.
                  </span>
                </p>
              </div>
              <div className="mt4 mt5-ns">
                <LinkButton
                  to="/disability/services/"
                  title="Explore our services"
                  shade="white"
                  className="mr2-ns"
                >
                  Explore our services
                </LinkButton>
                <LinkButton
                  to="/disability/contact-us/"
                  title="Get in touch"
                  shade="xdark"
                  className="mt3 mt0-ns mr2-ns"
                >
                  Get in touch
                </LinkButton>
              </div>
            </div>
          </div>
        </BannerFeatured>
        <Section background="bg-hex-image">
          <Container>
            <Row>
              <Box>
                <NavigationTiles
                  selectIconFn={iconSelector}
                  pages={[
                    SupportCoordination,
                    SupportedIndependentLiving,
                    DailyLivingSkills
                  ]}
                  currentPathname={location.pathname}
                  heading={
                    <h2 className="fw8 tc ts-display-3 mb4 color-lwb-theme-medium">
                      What kind of support are you looking for?
                    </h2>
                  }
                />
                <div className="w-70 w-34-m w-20-l mt4 pv3 ph3 tc center bg-white">
                  <ReadMoreLink
                    text={"See all disability services"}
                    title={"See all disability services"}
                    href="/disability/services/"
                  />
                </div>
              </Box>
            </Row>
          </Container>
        </Section>
        <div className="bg-lwb-theme-medium">
          <Container>
            <Row>
              <Box className="w-100">
                <LocationModal currentUrl={location.pathname} />
              </Box>
            </Row>
          </Container>
        </div>
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <Row>
              <Box className="w-100 layout-readable">
                <h2 className="fw8 ts-display-3 color-lwb-theme-medium">
                  Life Without Barriers supports the Disability Royal Commission
                </h2>
                <p>
                  Life Without Barriers supports the Royal Commission into
                  Violence, Abuse, Neglect and Exploitation of people with
                  Disability (DRC). We believe it is time for people living with
                  a disability to be heard, and for these experiences to
                  influence how we, as a nation, support people living with a
                  disability.
                </p>
                <LinkButton
                  className="tc fw8 mt4"
                  to="/disability/disability-royal-commission/"
                >
                  Learn more
                </LinkButton>
              </Box>
            </Row>
          </Container>
        </Section>
        <Container>
          <Row>
            <Box>
              <Heading size={2} className="tc color-lwb-theme-medium">
                How to access supports
              </Heading>
              <NdisJourney />
            </Box>
          </Row>
        </Container>
        <SectionBanner
          alignRight={true}
          backgroundImage={bannerLocationImage}
          backgroundPositon={"top"}
        >
          <Heading size={2}>We&apos;re here for you, across Australia</Heading>
          <p>
            Our disability services and programs are available in every state
            and territory. Get in touch to find out what supports are available
            near you.
          </p>
          <ReadMoreLink
            href="/disability/contact-us/"
            text="Get in touch"
            className="mt3 pt3"
            ga={{
              eventCategory: "Link",
              eventAction: "Click",
              eventLabel: "Get in touch"
            }}
          />
        </SectionBanner>
        <PromoPanelRightImage image={bannerWhyImage} color={greyXXXLight}>
          <div>
            <Heading size={2} className="color-lwb-theme-medium">
              Why choose Life Without Barriers?
            </Heading>
            <p>
              We’re a trusted NDIS provider that personalises services based on
              your needs.
            </p>
            <p>
              We started as a group of families creating disability services for
              our local community. And, over the past 25 years, we’ve focused on
              tailoring our services to address the individual needs of each
              person.
            </p>
            <p>
              Your rights as a person living with a disability are at the core
              of what we do. You can trust that no matter where we support you,
              across Australia, your needs come first.
            </p>
          </div>
        </PromoPanelRightImage>
        <ContactUsSection
          title="Get in touch with our disability services team"
          formId="disability"
          formKind="disability"
          phoneNumber="1800935483"
          emailAddress="yourLWB@lwb.org.au"
        />
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    bannerHeroImage: file(
      relativePath: { regex: "/disabilityHomepageHeroBanner.jpeg/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
    bannerWhyImage: file(
      relativePath: { regex: "/disabilityHomepageWhyBanner.jpeg/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
    bannerLocationImage: file(
      relativePath: { regex: "/Disweb-banner-water.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
    allLocationSupportsJson {
      edges {
        node {
          id
          supportId
          supportTitle
          supportIcon
          responseTitle
          responseBody
          responseIcon
          responseCta
          serviceUrl
        }
      }
    }
  }
`

export default IndexPage
