import React from "react"
import {
  Theme,
  media,
  Variables,
  IconNdis,
  IconTickCircle,
  IconPersonal,
  IconCare,
  IconDiscuss
} from "@life-without-barriers/react-components"
import styled from "styled-components"

const { white, greyXXXLight } = Variables
const { disabilityTheme } = Theme

const IconWrapperCustom = styled.div`
  background-color: ${disabilityTheme.xxxlight};
  border-radius: 50%;
  position: relative;
  margin: auto;
  padding: 1.2rem;
  width: 4rem;
  height: 4rem;

  ${media.medium`
    padding: 2rem;
    width: 7rem;
    height: 7rem;
  `}

  ${media.large`
    padding: 2.2rem;
    width: 8rem;
    height: 8rem;
  `}

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;

    ${media.notSmall`
      border-top: 1rem solid ${disabilityTheme.xxxlight};
      border-bottom: 0.7rem solid transparent;
      border-left: 0.7rem solid transparent;
      border-right: 0.7rem solid transparent;
      bottom: -1.6rem;
      left: calc(50% - 0.7rem);
    `}
  }

  &::after {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    background: ${disabilityTheme.dark};
    border-radius: 50%;
    bottom: calc(50% - 0.5rem);
    left: -1.7rem;
    z-index: 1;

    ${media.notSmall`
      bottom: -2rem;
      left: calc(50% - 0.5rem);
    `}
  }
`

const NdisIconWrapper = styled(IconWrapperCustom)`
  padding-top: 1.6rem;
  padding-bottom: 1.2rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
`

const JourneyWrapper = styled.div`
  > div:first-child {
    color: #7148b0;

    .journey-line {
      &::before {
        top: 50%;
        height: 50%;
        background: linear-gradient(
          90deg,
          rgba(113, 72, 176, 1) 0%,
          rgba(22, 99, 169, 1) 100%
        );

        ${media.notSmall`
          top: inherit;
          left: 50%;
          width: 50%;
          height: 0.3rem;
        `}
      }

      > div {
        background-color: #f0ecf5;

        &::before {
          ${media.notSmall`
            border-right: 0.7rem solid transparent;
            border-top: 1rem solid #F0ECF5;
          `}
        }
        &::after {
          background-color: #7148b0;
        }
      }
    }
  }

  > div:last-child .journey-line {
    &::before {
      height: 50%;
      bottom: -1.2rem;

      ${media.notSmall`
        width: 50%;
        height: 0.3rem;
      `}
    }

    ${media.notSmall`
      padding: 0 0.5rem 1rem 0.5rem;
      &::before {
        bottom: -0.7rem;
      }
    `}

    > div {
      ${media.medium`
        width: 8rem;
        height: 8rem;
      `}

      ${media.large`
        width: 9rem;
        height: 9rem;
      `}
  
      background-color: ${disabilityTheme.dark};

      &::before {
        ${media.notSmall`
          border-right: 0.7rem solid transparent;
          border-top: 1rem solid ${disabilityTheme.dark};
        `}
      }
    }
  }

  > div:nth-child(1) svg {
    ${media.notSmall`
      position: relative;
      top: 1rem;
    `}
  }

  > div:nth-child(4) svg {
    position: relative;
    top: -0.3rem;

    ${media.notSmall`
      top: -0.5rem;
      height: 3.8rem;
    `}

    ${media.large`
      top: -0.7rem;
      height: 4.5rem;
    `}
  }

  > div {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 90%;
      height: 0.1rem;
      background: ${greyXXXLight};
      bottom: 0;
      left: 10%;

      ${media.notSmall`
        height: 0;
      `}
    }

    &:last-of-type::after {
      height: 0;
    }
  }

  .journey-line {
    position: relative;

    &::before {
      background-color: ${disabilityTheme.dark};
      content: "";
      position: absolute;
      width: 3px;
      height: 100%;
      top: 0;
      left: calc(50% - 60px);

      ${media.notSmall`
        width: 100%;
        height: 0.3rem;
        top: inherit;
        bottom: -0.7rem;
        left: 0;
      `}
    }
  }
`

const NdisJourney = () => (
  <JourneyWrapper className="flex-ns fw7 ts-display-4 tc-ns color-lwb-theme-medium mv4 mv5-ns">
    <div className="w-20-ns flex items-stretch db-ns">
      <div className="journey-line w-40 w-100-ns pv3 pa3-ns pl0-ns">
        <NdisIconWrapper>
          <IconNdis color={"#7148B0"} />
        </NdisIconWrapper>
      </div>
      <div className="w-60 w-100-ns pt5-ns ph1-ns flex items-center">
        Apply to join the NDIS
      </div>
    </div>
    <div className="w-20-ns flex items-stretch db-ns">
      <div className="journey-line w-40 w-100-ns pv3 pa3-ns">
        <IconWrapperCustom>
          <IconTickCircle />
        </IconWrapperCustom>
      </div>
      <div className="w-60 w-100-ns pt5-ns ph1-ns flex items-center">
        Once approved, contact us
      </div>
    </div>
    <div className="w-20-ns flex items-stretch db-ns">
      <div className="journey-line w-40 w-100-ns pv3 pa3-ns">
        <IconWrapperCustom>
          <IconDiscuss />
        </IconWrapperCustom>
      </div>
      <div className="w-60 w-100-ns pt5-ns ph1-ns flex items-center">
        Discuss your specific needs
      </div>
    </div>
    <div className="w-20-ns flex items-stretch db-ns">
      <div className="journey-line w-40 w-100-ns pv3 pa3-ns">
        <IconWrapperCustom>
          <IconPersonal />
        </IconWrapperCustom>
      </div>
      <div className="w-60 w-100-ns pt5-ns ph1-ns flex items-center">
        Build a personalised support plan
      </div>
    </div>
    <div className="w-20-ns flex items-stretch db-ns">
      <div className="journey-line w-40 w-100-ns pv3 pa3-ns pr0-ns">
        <IconWrapperCustom>
          <IconCare color={white} />
        </IconWrapperCustom>
      </div>
      <div className="w-60 w-100-ns pt5-ns ph1-ns flex items-center">
        Access your supports
      </div>
    </div>
  </JourneyWrapper>
)

export default NdisJourney
